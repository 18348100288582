<template>
  <div class="container oci-check-in-out-body">    
    <WasteNumberView/>
    <p></p>
    <b-card class="oci-check-in-out-card">  
        <b-row class="pb-0 justify-content-center">
            <b>Waste collected</b>
        </b-row>
        <b-row>
            <b-col>
            <b-form-group
                label=""
                label-for="waste-select"
            >
                <div v-for="(item, index) in bookingDockets.items.filter(item => item.isVisible)" :key="index">
                <b-form-group
                label="Material"
                label-for="waste-material"
                >
                <b-form-select
                    :id="'waste-select-' + index"
                    v-model="item.material"
                    :options="wasteMaterialOptions"
                    :disabled="isInputDisabled"
                />
                </b-form-group>
                <b-row class="align-items-center">
                  <b-col cols="3">
                    <b-form-group
                    label="Bins"
                    label-for="waste-bins"
                    >                  
                      <b-form-input
                      v-model="item.bins"
                      placeholder=""
                      name="name-edit"
                      :disabled="isInputDisabled"
                      />
                    </b-form-group>  
                  </b-col>
                  <b-col cols="4">
                    <b-form-group
                    label="Capacity-Lt"
                    label-for="waste-capacity"
                    >                  
                      <b-form-select
                      :id="'waste-bin-select-' + index"
                      v-model="item.bin_type"
                      :options="wasteBinOptions"
                      placeholder="Choose a waste bin"
                      :disabled="isInputDisabled"
                      ></b-form-select>
                    </b-form-group>  
                  </b-col>
                  <b-col class="col-2 oci-projects-button-container">
                    <b-button
                      type="submit"
                      @click="removeItem(item.id)"
                      :disabled="isInputDisabled"
                    >
                      -
                    </b-button>
                  </b-col>
                  <b-col class="col-2 oci-check-in-button-container">
                    <b-button
                      type="submit"
                      @click="addItem"
                      :disabled="isInputDisabled"
                    >
                      +
                    </b-button>
                  </b-col>
                </b-row>
                </div>                
            </b-form-group>
            </b-col>
        </b-row> 
        
        <b-row class="pb-1">
          <b-col class="col-5 oci-projects-button-container">
            <b-button
              type="submit"
              @click="GoToPreviousPage"
              class="w-100"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />              
              Back
            </b-button>
          </b-col>
          <b-col class="col-7 oci-check-in-button-container">
            <b-button
              type="submit"
              @click="GoToNextPage"
              class="w-100"
            >
              Next
              <feather-icon
                icon="ChevronRightIcon"
                size="12"
              />              
            </b-button>
          </b-col>
        </b-row> 

    </b-card>

  </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import { BImg, BCard, BRow, BCol, BForm, BFormGroup, BFormSelect, BInputGroupAppend, BFormInput, BButton, BInputGroup, } from 'bootstrap-vue'
import { bookingsMethods } from '@/components/ui/BookingsFunctions'
import WasteNumberView from '@/views/WasteNumberView.vue'
import { generalMethods } from '@/components/General-Functions'

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    WasteNumberView,
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        number_of_240_bins: 0,
        number_of_360_bins: 0,
        number_of_660_bins: 0,
      }),
    },
  },  
  data() {
    return {
      wasteBinOptions: [
        { text: '240', value: '240' },
        { text: '360', value: '360' },
        { text: '660', value: '660' },
      ],
    };
  },   
  computed: {
    ...mapGetters({ currentBooking: 'app/getCurrentBookingData' }),    
    ...mapGetters({ bookingDockets: 'app/getBookingDocketDetails' }),
    ...mapGetters({ wasteMaterials: 'app/getWasteMaterials' }),
    wasteMaterialOptions() {
      return this.wasteMaterials.map(material => ({
        text: material.name,
        value: material.id,
      }));
    },
    isInputDisabled() {
      return (
        this.currentBooking?.status === 'completed' ||
        this.currentBooking?.status === 'rejected' ||
        this.currentBooking?.status === null
      );
    }
  },
  methods: {
    GoToPreviousPage() {
      this.$router.go(-1)
    },
    GoToNextPage() {
      if (this.currentBooking.status === 'to_do' || this.currentBooking.status === 'started') {        
        for (const item of this.bookingDockets.items) {
          if (item.bins <= 0 || item.material === null || item.material < 0) {
            if (item.isVisible) {
              generalMethods.messageBox('', 'Please set a quantity and type for each waste.', 'warning');
              return;
            }
            item.isVisible = false;
          }/* else {
            item.isVisible = true;
          }*/
        }
        console.log('before saveWasteCollectedDetails');
        bookingsMethods.saveWasteCollectedDetails(this.currentBooking.id, this.bookingDockets.id, this.bookingDockets.items);
      }      
      console.log('before router');
      this.$router.push('/waste_cli_sign');
    },
    addItem() {
      const newItem = {
        id: -1,
        material: -1,
        number_of_240_bins: 0,
        number_of_360_bins: 0,
        number_of_660_bins: 0,
        number_of_bins: 0,
        bins: 0,
        bin_type: '240',
        isVisible: true,
      };
      this.bookingDockets.items = [...this.bookingDockets.items, newItem];
      this.$forceUpdate();
    },
    removeItem(itemId) {
      console.log('removeItem', itemId);
      const itemIndex = this.bookingDockets.items.findIndex(item => item.id === itemId && item.isVisible);
      if (itemIndex === -1) {
        console.warn('Item not found.');
        return;
      }
      const visibleItemsCount = this.bookingDockets.items.filter(item => item.isVisible).length;
      console.log('Visible items count:', visibleItemsCount);
      if (visibleItemsCount > 1) {
        // Set isVisible to false for the located item
        this.bookingDockets.items[itemIndex].isVisible = false;
        // Additional condition if item ID is less than 0
        if (this.bookingDockets.items[itemIndex].id < 0) {
          this.bookingDockets.items[itemIndex].bins = -1;
        }
      } else {
        console.warn('Cannot remove the last visible item.');
      }
      this.$forceUpdate(); // Ensure the UI reflects changes
    },
    updateSelectedWasteBin(item) {
      if (item.number_of_240_bins > 0) {
        this.selectedWasteBin = '240';
      } else if (item.number_of_360_bins > 0) {
        this.selectedWasteBin = '360';
      } else if (item.number_of_660_bins > 0) {
        this.selectedWasteBin = '660';
      } else {
        this.selectedWasteBin = null; // No value selected
      }
    },
  },  
}
</script>
